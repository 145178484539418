html {
  font-family: "Open Sans", sans-serif !important;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 4rem);
  padding: 0.5rem 2rem;
  border-bottom: 1px solid #e3e3e3;
  background-color: white;
}

.brand {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 900;
  color: #071528;
  letter-spacing: -0.5px;
}

.brand img {
  width: 3rem;
  margin-right: 0.8rem;
}

.fa-github {
  font-size: 1.6rem;
  color: #071528;
  cursor: pointer;
}

.sub-header {
  text-align: center;
  font-size: 3rem;
  font-weight: 900;
  color: #071528;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem;
}

.sub-header div {
  text-align: center;
}

.description {
  font-size: 1.4rem;
  font-weight: 300;
  color: #071528;
  text-align: center;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem;
}

.description div {
  max-width: 600px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.get-started-button {
  width: 100px;
  text-align: center;
  padding: 10px 20px;
  background-color: #4E4990;
  color: white;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  -webkit-box-shadow: 2px 14px 14px -9px rgba(65, 61, 122, 0.3);
  -moz-box-shadow: 2px 14px 14px -9px rgba(65, 61, 122, 0.3);
  box-shadow: 2px 14px 14px -9px rgba(65, 61, 122, 0.3);
  transition: box-shadow 0.5s, transform 0.5s;
}

.demo-button {
  width: 50px;
  text-align: center;
  padding: 10px 20px;
  background-color: #9B8CCE;
  color: #071528;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  -webkit-box-shadow: 2px 14px 14px -6px rgba(155, 140, 206, 0.3);
  -moz-box-shadow: 2px 14px 14px -6px rgba(155, 140, 206, 0.3);
  box-shadow: 2px 14px 14px -6px rgba(155, 140, 206, 0.3);
  transition: box-shadow 0.5s, transform 0.5s;
}

.get-started-button:hover {
  transform: scale(1.02);
  -webkit-box-shadow: 2px 14px 14px -5px rgba(65, 61, 122, 0.5);
  -moz-box-shadow: 2px 14px 14px -5px rgba(65, 61, 122, 0.5);
  box-shadow: 2px 14px 14px -5px rgba(65, 61, 122, 0.5);
}

.demo-button:hover {
  transform: scale(1.02);
  -webkit-box-shadow: 2px 14px 14px -5px rgba(155, 140, 206, 0.5);
  -moz-box-shadow: 2px 14px 14px -5px rgba(155, 140, 206, 0.5);
  box-shadow: 2px 14px 14px -5px rgba(155, 140, 206, 0.5);
}

.features {
  width: 100%;
  background-color: #E8E5FB;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}

.card-holder {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  width: calc(100% / 3 - 4rem);
  padding: 4rem 2rem;
  text-align: center;
  align-items: center;
  color: #071528;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.card-description {
  font-size: 1.1rem;
  font-weight: 400;
  padding: 0px 2rem;
}

.heading {
  text-align: center;
  font-size: 2rem;
  font-weight: 800;
}

.heading span {
  background-image: linear-gradient(to right, #4E498F, #9A8CCD);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 80% 10%;
}

.wrapper {
  padding: 0rem 2rem;
}

.get-started-wrapper {
  max-width: 900px;
  margin: auto;
}

.get-started-heading-1 {
  font-size: 1.5rem;
  font-weight: 700;
}

.get-started-command {
  background-color: #f7f7ff;
  padding: 20px;
  color: #071528;
  border-radius: 8px;
  overflow-x: auto;
}

.get-started-heading-2 {
  font-size: 1.2rem;
  font-weight: 600;
}

iframe {
  width: 100%;
  height: calc(100vh - 6rem);
  border: none;
  outline: none;
  margin-top: 3rem;
  border-radius: 30px;
  -webkit-box-shadow: 2px 14px 14px -9px rgba(7, 21, 40, 0.5);
  -moz-box-shadow: 2px 14px 14px -9px rgba(7, 21, 40, 0.5);
  box-shadow: 2px 14px 14px -9px rgba(7, 21, 40, 0.5);
}

.footer {
  border-top: 1px solid #e3e3e3;
  padding: 20px;
  color: #101010;
  background-color: #eae9fe;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

pre {
  font-family: "Open Sans", sans-serif;
}

.spacer-1 {
  width: 100%;
  height: 3rem;
}

.spacer-2 {
  width: 100%;
  height: 1.5rem;
}

#iframe-wrapper {
  background-color: #f7f7ff;
}

@media only screen and (max-width: 800px) {
  .card {
    width: calc(100% / 2 - 4rem);
  }
}

@media only screen and (max-width: 600px) {
  .header {
    width: calc(100% - 2rem);
    padding: 0.5rem 1rem;
  }
  .brand img {
    width: 2rem;
  }
  .brand {
    font-size: 1rem;
  }
  .fa-github {
    font-size: 1.2rem;
  }
  .sub-header {
    font-size: 1.6rem;
  }
  .description {
    font-size: 1rem;
  }
  .button-wrapper {
    margin-top: 2.5rem;
  }
  .card {
    width: calc(100% - 2rem);
    padding: 2rem 0rem 2rem 0rem;
  }
  .card-title {
    font-size: 1.2rem;
  }
  .card-description {
    font-size: 1rem;
    padding: 0rem;
  }
  .heading {
    font-size: 1.4rem;
    text-align: left;
  }
  .get-started-heading-1 {
    font-size: 1.1rem;
  }
  .get-started-heading-2 {
    font-size: 1rem;
  }
  .get-started-command {
    background-color: #eff3f7;
    padding: 1rem;
    margin-top: 1rem;
  }
  .wrapper {
    padding: 0rem 2rem;
  }
  table {
    margin-top: 0rem;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  .footer {
    font-size: 0.8rem;
  }
  .heading span {
    background-image: none;
  }
  .spacer-1 {
    width: 100%;
    height: 2rem;
  }
  
  .spacer-2 {
    width: 100%;
    height: 1rem;
  }
}
